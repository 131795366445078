import { graphql, Link, useStaticQuery } from "gatsby"
import Img, { FluidObject } from "gatsby-image"
import React from "react"

const InstagramListInfo = () => {
  const data = useStaticQuery(graphql`
    query IGListQuery {
      list: file(relativePath: { eq: "showcase-list2.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      profile: file(relativePath: { eq: "showcase-expand.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <div className="flex flex-col">
      <div className="w-full flex">
        <div className="w-1/3">
          <Img
            fluid={data.list.childImageSharp.fluid}
            alt={`list example`}
            className="rounded-md shadow-custom mb-8"
          />
        </div>
        <div className="w-2/3 flex flex-col items-center">
          <div className="flex flex-col justify-around h-full w-2/3">
            <div>
              <h3>Discover New Influencers</h3>
              <p>
                Find Instagram influencers that have followers that align with
                your audience.
              </p>
              <Link
                to="/lists/?ig"
                className="bg-light-blue w-32 mx-auto flex items-center justify-center px-3 py-2 border border-transparent text-base leading-6 font-bold rounded-md text-white hover:text-black hover:shadow-xl focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out"
              >
                View Lists
              </Link>
            </div>
            <div>
              <h3>Data is King</h3>
              <p>
                Vet them with our data to make sure that they will provide you
                with a positive ROI.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full">
        <div className="w-1/3 flex items-center justify-center">
          <div className="m-auto">
            <h3>Create Your Own</h3>
            <p>
              Fine tune your influencer search by creating your own list
              tailored to you unique use case.
            </p>
            <Link
              to="/custom-list/?ig"
              className="bg-dark-green w-32 mx-auto flex items-center justify-center px-3 py-2 border border-transparent text-base leading-6 font-bold rounded-md text-white hover:text-black hover:shadow-xl focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out"
            >
              Create List
            </Link>
          </div>
        </div>
        <div className="w-2/3">
          <Img
            fluid={data.profile.childImageSharp.fluid}
            alt={`list example`}
            className="rounded-md shadow-custom mb-8 w-4/5 m-auto"
          />
        </div>
      </div>
    </div>
  )
}

export default InstagramListInfo
