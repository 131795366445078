import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img, { FluidObject } from "gatsby-image"

const Hero = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      image: file(relativePath: { eq: "showcase-laptop2.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <header>
      <div className="relative bg-white overflow-hidden font-sans">
        <div className="max-w-screen-xl mx-auto flex flex-col">
          <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-8 lg:w-full lg:pb-10">
            <main className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h1
                  id="home"
                  className="text-4xl text-center tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-4xl"
                >
                  Boost Brand <span className="text-green">Awareness</span> &
                  <br />
                  Increase Product <span className="text-blue">Sales</span>
                  <br />
                </h1>
                <p className="mt-3 text-base text-center rounded py-2 text-black sm:mt-5 sm:text-base sm:mx-auto md:mt-5 md:text-sm lg:mx-0">
                  Find <span className="underline">micro influencers</span> that
                  increases your conversions and boosts your social media
                  presence.
                </p>
                <div className="mt-5 sm:mt-8 w-full mx-auto text-center flex justify-center items-center">
                  <div className="mt-3 sm:mt-0 sm:ml-3 flex justify-end w-1/2">
                    <Link
                      to="/lists/?alt"
                      className="gradient w-32 flex items-center justify-center px-3 py-2 border border-transparent text-base leading-6 font-bold rounded-md text-white hover:text-black hover:shadow-xl focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out"
                    >
                      View Lists
                    </Link>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-3 flex justify-start w-1/2">
                    <a
                      style={{ backgroundColor: "rgba(26, 32, 44, 0.2)" }}
                      href="https://omnifluence-app.web.app/sign-up/sample"
                      className="w-32 flex items-center bg-gray-800 justify-center px-3 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-green bg-indigo-100 hover:text-blue hover:bg-indigo-50 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-300 transition duration-150 ease-in-out"
                    >
                      Try for free
                    </a>
                  </div>
                </div>
              </div>
            </main>
          </div>
          <div className="mx-auto w-2/3">
            <div className="flex items-center justify-center">
              <a
                href="https://appsumo.com/products/instagram-micro-influencer-list?utm_source=badge"
                target="_blank"
                rel="noopener"
              >
                <img
                  src="https://appsumo2nuxt-cdn.appsumo.com/img/as-badge-featured.cf14670.png"
                  alt="AppSumo badge"
                />
              </a>
            </div>
            <Img
              fluid={data.image.childImageSharp.fluid}
              alt={`list example`}
            />
          </div>
        </div>
      </div>
    </header>
  )
}

export default Hero
